<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div style='display: flex;flex-direction: row;justify-content: space-between;'>
          <div>
            <el-form size='mini' :inline='true'>
              <el-form-item label=''>
                <el-date-picker
                  v-model='searchCondition.ym'
                  style='width: 140px'
                  type='month'
                  format='yyyy年MM月'
                  placeholder='选择月' size='small' value-format='yyyy-MM' @change='handleQuery'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <artist-search-custom :type='`trend_enter`' :show-search-tool='false' @handleSelect='selectArtist'
                                      :show-first-group='true' style='width: 100%' />
              </el-form-item>
              <el-form-item>
                <artist-search @handleSelect='handleSelectKol' v-if='userPermissions.indexOf("trend_enter_all_groups")>-1'></artist-search>
              </el-form-item>
              <el-form-item>
                <el-button @click='handleQuery' type='primary' icon='el-icon-search' >查询</el-button>
              </el-form-item>
              <!--              <el-form-item>-->
              <!--                <el-button><i class='el-icon-arrow-left'></i>日志</el-button>-->
              <!--              </el-form-item>-->
            </el-form>
          </div>
          <div style='display: grid;place-items: center'>
            <el-link icon='el-icon-s-platform' type='primary' @click='jumpToPage("trend_rank")'>榜单</el-link>
          </div>
        </div>
<!--        <div class='query-panel'>-->
<!--          <span>月份：{{searchCondition.ym||''}}</span>-->
<!--          &nbsp;-->
<!--          &nbsp;-->
<!--          <span>红人昵称：{{searchCondition.nickname||''}}</span>-->
<!--         </div>-->
        <div class='enter-panel'>
          <TrendRecord ref='refTrendRecord'
                       :search-condition='{artist_id:searchCondition.artist_id,ym:searchCondition.ym}'></TrendRecord>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import TrendRecord from '@/pages/trend/enter/TrendRecord'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import { mapGetters } from 'vuex'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'TrendEnterList',
  components: {
    ArtistSearch,
    PageHeaderLayout,
    ArtistSearchCustom,
    ApeTable,
    ApeDrawer,
    TrendRecord
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      groups: [],//当前页面展示的分组列表
      artists: [],//可选红人列表
      tabPosition: 'left',
      tabName: '',
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      artistPlatforms: [],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        width_height: '480px',
        show_footer: false
      },
      searchCondition: {
        group_id: null,
        artist_id: null,
        nickname: null,
        ym: null,
        platform_code: null,
        platform_name: ''
      },
      scopeType: 'all',
      showFired: false
    }
  },
  created() {
    // 平台
    this.getCurrentMonth()
  },
  methods: {
    getCurrentMonth() {
      let currentDate = new Date()
      //昨天的日期
      currentDate.setDate(currentDate.getDate() - 1)
      this.searchCondition.ym = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1)
    },
    handleSelectKol(row) {
      // this.$notify.info(JSON.stringify(row))
      this.searchCondition.artist_id = row.id || null
      this.searchCondition.nickname = row.nickname || null
      this.handleQuery()
    },
    async selectArtist(selectInfo) {
      this.searchCondition.artist_id = selectInfo.artist_id
      this.searchCondition.nickname = selectInfo.nickname
      this.searchCondition.group_id = selectInfo.group_id
      this.searchCondition.group_name = selectInfo.group_name

      this.handleQuery()
    },
    jumpToPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   params: params
      // })
      // window.open(href, '_blank')
    },
    handleQuery() {
      let query = { artist_id: this.searchCondition.artist_id, ym: this.searchCondition.ym }
      sessionStorage.setItem('page-trend-query', JSON.stringify(query))
      this.$refs['refTrendRecord'].show(this.searchCondition)
    }
  }
}
</script>

<style scoped>
.searchCondition {
  margin-bottom: 10px;
}
</style>
<style>

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #1890ff;
  background-color: #FFFFFF;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;
  font-weight: 600;
  font-size: 14px;
}

.el-select > .el-input {
  width: 200px !important;
}
</style>
